import React, { useState, useEffect, Fragment } from "react";
import jwt_decode from "jwt-decode";

import { Container, Row, Col, Alert, Card, CardText, Button, ButtonGroup, CardHeader, CardBody, UncontrolledTooltip } from "reactstrap";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import config from "../auth_config.json";

const { apiOrigin = "http://localhost:3001" } = config;

export const View = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  var refreshTimers = []

  //const [barrierModeSelected, setBarrierModeSelected] = useState(null);

  const onBarrierBtnClick = (mode) => {
    console.log(mode)
    setBarrierMode(mode)
    //setBarrierModeSelected(mode);
  }

  useEffect( () => {
    callApi()
    scheduleRefresh()
  }, []);

  const scheduleRefresh = () => {
    var id;
    id = setTimeout(()=>{
      clearRefreshTimers() // Nuke any existing timers

      callApi();
      scheduleRefresh()
    }, 30*1000)

    refreshTimers.push(id)
  }

  const clearRefreshTimers = () => {
    var id
    while((id = refreshTimers.pop()) != null) {
      clearTimeout(id)
    }

  }

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [scopes, setScopes] = useState([]);

  useEffect(() => {
    (async () => {
      setScopes(jwt_decode(await getAccessTokenSilently())["scope"].split(' '))
    })();
  }, [getAccessTokenSilently]);

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/location-status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      var spaces = [];
      var occupiedSpaces = [];
      for (const [key, value] of Object.entries(responseData.spaces)) {

        spaces[key] = responseData.spaces[key];
        if(responseData.spaces[key].state) {
          occupiedSpaces[key] = responseData.spaces[key];
        }
      }

      /*occupiedSpaces = {'river-lawn-1': true,
                        'river-lawn-2': true,
                        'river-lawn-3': true,
                        'river-lawn-4': true,
                        'river-lawn-5': true,
                        'river-lawn-6': true,
                        'river-lawn-7': true,
                        'river-lawn-8': true,
                        'river-lawn-9': true,
                        'river-lawn-10': true,
                        'river-lawn-11': true,
                        'river-lawn-12': true,
                        'river-lawn-13': true,
    }*/

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
        normal_full: responseData.normal_full,
        accessible_full: responseData.accessible_full,
        barrier_closed: responseData.barrier_closed,
        spaces: spaces,
        occupiedSpaces: occupiedSpaces,
        barrier_mode: responseData.barrier_mode
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const setBarrierMode = async (mode) => {
    try {
      const token = await getAccessTokenSilently();

      await fetch(`${apiOrigin}/barrier-mode`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        },
        body: JSON.stringify({ "barrier-mode": mode }),
      });
    
      callApi();

    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

          
          { !scopes.includes("view:parking") ? (
          <Container>
            <h1>Account setup needed</h1>
            <p>Your account needs to be connected to your car park. Please <a href="mailto:info@sensational.systems">contact us to get set up</a>.</p>
          </Container>
          ) : 
          (
            <Container>
              <h1>River Lawn House Car Park</h1>
              <Row className="mt-4">
                <Col xs="6" lg="3">
                  <Card>
                    <CardHeader>Standard Spaces</CardHeader>
                    <CardBody>
                      <CardText>{state.normal_full ? "Full" : "Available" }</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="6" lg="3">
                <Card>
                    <CardHeader>Accessible Spaces</CardHeader>
                    <CardBody>
                      <CardText>{state.accessible_full ? "Full" : "Available" }</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="6" lg="2">
                  <Card>
                    <CardHeader>Barrier State</CardHeader>
                    <CardBody>
                      <CardText>{state.barrier_closed ? "Closed" : "Open" }</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="6" lg="4">
                  <Card>
                    <CardHeader>Barrier Mode</CardHeader>
                    <CardBody>
                        <ButtonGroup>
                          <Button color="secondary" onClick={() => onBarrierBtnClick("automatic")} active={state.barrier_mode === "automatic"}>Automatic</Button>
                          <Button color="secondary" onClick={() => onBarrierBtnClick("up")} active={state.barrier_mode === "up"}>Stay up</Button>
                          <Button color="secondary" onClick={() => onBarrierBtnClick("down")} active={state.barrier_mode === "down"}>Stay down</Button>
                        </ButtonGroup>
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="mt-4">
                  <Card>
                    <CardHeader>Current occupancy</CardHeader>
                    <CardBody>
                      <div className="car-park-display mt-2">
                        <img alt="Carpark layout" src="/images/background.svg" />
                        
                        {state.showResult && (
                          <>
                          {Object.keys(state.occupiedSpaces).map(space => (
                            <Fragment key={"frag-" + space}>
                              <div id={space} key={space} className={"space-marker " + space}>
                                  { state.occupiedSpaces[space].seconds_ago / 60 > 60 ? 
                                    <img src="/images/alert.svg" /> : "" }
                              </div>
                              <UncontrolledTooltip
                                key={"tootip-" + space}
                                target={space}
                              >
                                Occupied for { Math.round(state.occupiedSpaces[space].seconds_ago / 60) } minutes
                              </UncontrolledTooltip>  
                            </Fragment>
                          ))}
                          

                          </>
                        )}
                        
                    </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
            
      </div>
    </>
  );
};

export default withAuthenticationRequired(View, {
  onRedirecting: () => <Loading />,
});
